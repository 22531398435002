import axios from 'axios'
import { store } from './main'

export default {
  get (path, payload) {
    return new Promise((resolve, reject) => {
      axios.get(store.state.baseURL + path, {
        params: payload
      }).then((res) => {
        resolve(res)
      }).catch((error) => {
        console.error(`API request to POST ${path} failed.`)
        console.error(error)
        reject(error)
      })
    })
  },

  post (path, payload) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.baseURL + path, payload).then((res) => {
        resolve(res)
      }).catch((error) => {
        console.error(`API request to POST ${path} failed.`)
        console.error(error)
        reject(error)
      })
    })
  }
}